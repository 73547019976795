<template>
  <div class="custom-page">
    <div class="custom-page__content">
      <p>
        <b>Impressum::</b><br />
        <b>Herausgeber</b><br />
        Julius-Maximilians-Universität Würzburg<br />
        Postanschrift: Sanderring 2, 97070 Würzburg <br />
        Telefon: +49 931 31-0<br />
        Fax: +49 931 31-82600<br />
        Email: info@uni-wuerzburg.de<br />
      </p>

      <p>
        <b>Verantwortlicher für den Inhalt</b><br />
        Professor Dr. Jörn Hurtienne,<br />
        Franzisca Maas, M.Sc. <br />
        Professur für Psychologische Ergonomie <br />
        Universität Würzburg<br />
        Campus Hubland Nord<br />
        Oswald-Külpe-Weg 82<br />
        97074 Würzburg<br />
        Tel.: 0931 31-85370<br />
        E-Mail: franzisca.maas@uni-wuerzburg.de
      </p>

      <p>
        <b>Rechtsform und Vertretung</b><br />
        Die Julius-Maximilians-Universität Würzburg ist eine Körperschaft des Öffentlichen Rechts
        und zugleich staatliche Einrichtung nach Art. 1 Abs. 1 BayHSchG. Sie wird gesetzlich
        vertreten durch den Präsidenten Prof. Dr. Paul Pauli.
      </p>
      <p>
        <b>Zuständige Aufsichtsbehörde</b><br />
        Bayerisches Staatsministerium für Wissenschaft und Kunst<br />
        Postanschrift: Salvatorstraße 2, 80327 München <br />
        Umsatzsteuer-Identifikationsnummer<br />
        Die Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz lautet DE 134187690.
      </p>
      <p>
        <b>Haftungsausschluss</b><br />
        Die Julius-Maximilians-Universität Würzburg übernimmt keine Haftung für die Richtigkeit,
        Vollständigkeit und Verfügbarkeit der bereit gestellten Inhalte, insbesondere wird eine
        Haftung für Schäden der Nutzer des Internetangebotes ausgeschlossen. Dies gilt insbesondere
        auch für IT-Schäden, die durch den Download von Inhalten entstehen.
        <br />Die Julius-Maximilians-Universität Würzburg übernimmt darüber hinaus keine Haftung für
        die Inhalte externer Links und Kommentare von Dritten, insbesondere auf den
        Social-Media-Kanälen der Julius-Maximilians-Universität Würzburg. Für den Inhalt der
        verlinkten Seiten sind ausschließlich deren Betreiberinnen und Betreiber verantwortlich.
      </p>
      <p>
        <b>Bildrechte</b><br />
        Ein Dankeschön an Nando Lester, der uns aus seinen Drohnenaufnahmen vom Hubland
        Hintergrundbild der Startseite zur Nutzung zur Verfügung gestellt hat. Die Zeichnung des
        hubbels auf der Seite "der hubbel stellt sich vor" wurde von Cassandra Rabe entworfen und
        uns zur Verfügung gestellt.
      </p>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";

export default {
  name: "Impressum",
  components: { Footer },
};
</script>

<template>
  <div class="custom-page">
    <h1>
      Darf ich mich vorstellen? <br />
      Der hubbel stellt sich vor.
    </h1>
    <div class="custom-page__image-container">
      <img src="../../../client-shared/img/hubbel-sketch2.png" alt="hubbel-Zeichnung" />
    </div>

    <div class="custom-page__content">
      <p>
        Durch hubbel können Anwohnerinnen und Anwohner Informationen, Fragen und Antworten zu
        aktuellen Geschehnissen am Hubland mit ihren Nachbarinnen und Nachbarn austauschen.
        <br />
        <br />
        Dazu gibt es drei Zugänge, über die der hubbel genutzt werden kann: 1) einen “hybriden
        hubbel” am Hubland, 2) eine Webseite, 3) eine mobile Version. Über alle drei Zugangspunkte
        ist das digitale System zum Austausch von Beiträgen zugänglich. Das bedeutet, Beiträge in
        Form von (digitalen) Postkarten können über alle Zugänge gelesen und neue Postkarten zu
        hublandspezifischen Themen zugeordnet werden.
        <br />
        <br />
        Entwickelt wurde der hubbel in einer Kooperation zwischen der Universität Würzburg und
        Bürgerinnen und Bürgern des Hublands.
      </p>
      <h2>Über meine Redaktion</h2>
      <p>
        Dabei werde ich von einer Redaktion betreut, die sich aus engagierten Bürgerinnen und
        Bürgern zusammensetzt. Den Redakteurinnen und Redakteuren ist gemein, dass sie selbst am
        Hubland wohnen oder arbeiten und sich gerne für eine stetige Verbesserung des Stadtteils
        sowie für einen nachbarschaftlichen Austausch zwischen Bürgerinnen und Bürgern einsetzen.
        Die Redakteurinnen und Redakteure machen dies ehrenamtlich und es gibt keine speziellen
        Anforderungen, die zu einer Beteiligung an der Redaktion erfüllt werden müssen.
        <br />
        <b
          >Außerdem ist jede und jeder Hubländer*in willkommen, der Redaktion beizutreten! Bitte
          wende dich bei Interesse an
          <a href="mailto:hubbelredaktion@protonmail.com">hubbelredaktion@protonmail.com</a>.
        </b>
        <br />
        <br />
        Die Redaktion sorgt im Hintergrund vor allem für zwei Dinge: Erstens unterstützt sie die
        Ordnung und Übersichtlichkeit der Informationen durch eine Kategorisierung der eingereichten
        Postkarten. Dazu legt die Redaktion auch neue Themen an, wenn dies angebracht ist. Neue
        Themen ergeben sich vor allem dadurch, dass neue Projekte am Hubland starten und dazu
        Informationen den hubbel erreichen. Außerdem werden die Hintergrundinformationen, die in
        jedem Thema neben den entsprechenden Beiträgen stehen, von der Redaktion zusammengestellt.
        <br />
        Zweitens überprüft die Redaktion die Inhalte der eingereichten Postkarten auf Hassrede und -
        soweit möglich - inhaltliche Korrektheit, bevor die Postkarten öffentlich einsehbar werden.
        Dabei legt die Redaktion großen Wert darauf, keine Meinungen zu unterdrücken oder bestimmte
        Themen präsenter zu machen als andere, sondern ist bemüht, den hubbel für alle Lesenden und
        Schreibenden angenehmer zu gestalten.
        <br />
        Über diese beiden Kernaufgaben hinaus beteiligen sich die Redakteurinnen und Redakteure auch
        als Autorinnen und Autoren und halten gezielt nach neuen Informationen über das Hubland und
        die aktiven Projekte Ausschau und sichten dafür beispielsweise regelmäßig
        Stadtratsbeschlüsse und die Lokalpresse.
      </p>

      <p>
        Liebe Grüße
        <br />
        dein hubbel
      </p>
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";

export default {
  name: "AboutUs",
  components: { Footer },
};
</script>

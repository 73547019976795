import axios from "axios";
import { authHeader } from "@/services/auth-header";
import { baseURL } from "@/services/api";

const AdminApi = axios.create({
  baseURL: baseURL + "api/admin/",
  headers: {
    "Content-type": "application/json",
  },
});

export default {
  async updateUserPermissions(user_id, level_of_user) {
    return await AdminApi.post(
      "updateUserPermissions",
      { user_id: user_id, level_of_user: level_of_user },
      {
        headers: { Authorization: `Bearer ` + authHeader() },
      }
    );
  },

  async updateUserVerification(user_id, verified) {
    return await AdminApi.post(
      "updateUserVerification",
      { user_id: user_id, verified: verified },
      {
        headers: { Authorization: `Bearer ` + authHeader() },
      }
    );
  },
  async updateUserSubscription(user_id, get_emails_editor) {
    return await AdminApi.post(
      "updateUserSubscription",
      { user_id: user_id, get_emails_editor: get_emails_editor },
      {
        headers: { Authorization: `Bearer ` + authHeader() },
      }
    );
  },
  async updateUserTeam(user_id, team) {
    return await AdminApi.post(
      "updateUserTeam",
      { user_id: user_id, team: team },
      {
        headers: { Authorization: `Bearer ` + authHeader() },
      }
    );
  },
  async getAllUsers() {
    return await AdminApi.get("getAllUsers", {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },
};

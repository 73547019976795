<template>
  <div class="administration-comp">
    <div style="float: left">
      <v-navigation-drawer permanent>
        <v-list>
          <v-list-item
            v-for="option in Options"
            :key="option.id"
            :class="{ active: option.id === current_Option, 'administration-item': true }"
            @click="current_Option = option.id"
          >
            <v-list-item-icon>
              <v-icon>{{ option.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ option.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </div>
    <div ref="admin_body">
      <UserPermissions v-if="current_Option === 'user_options'" />
      <TopicOptions v-if="current_Option === 'topic_options'" />
      <RedaktionOptions v-if="current_Option === 'redaktion_options'" />
    </div>
  </div>
</template>

<script>
import UserPermissions from "@/components/AdministrationsComponents/UserOptions";
import TopicOptions from "@/components/AdministrationsComponents/TopicOptions";
import RedaktionOptions from "@/components/AdministrationsComponents/RedaktionOptions";
export default {
  name: "Administration",
  components: { RedaktionOptions, TopicOptions, UserPermissions },

  data: () => ({
    Options: [
      { title: "User Permissions", icon: "mdi-account-multiple", id: "user_options" },
      { title: "Topics", icon: "mdi-star", id: "topic_options" },
      { title: "Redaktion", icon: "mdi-archive-search", id: "redaktion_options" },
    ],
    current_Option: null,
  }),
};
</script>
<style scoped></style>

<template>
  <div class="custom-page">
    <div class="custom-page__content">
      <p>
        <b>Datenschutz</b><br />
        Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der
        EU-Datenschutzgrundverordnung (DSGVO), ist:<br />
        Der behördlich bestellte Datenschutzbeauftrage der Julius-Maximilians-Universität
        Würzburg<br />
        Sanderring 2<br />
        97070 Würzburg<br />
        Telefon: +49 931 31-0<br />
        Email: datenschutz@uni-wuerzburg.de
      </p>
      <p>
        Ihre Betroffenenrechte<br />
        Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit
        folgende Rechte ausüben:
      </p>
      <ul>
        <li>
          Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),
        </li>
        <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
        <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
        <li>
          Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten
          noch nicht löschen dürfen (Art. 18 DSGVO),
        </li>
        <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
        <li>
          Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen
          Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).
        </li>
      </ul>
      <br />
      <p>
        Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für
        die Zukunft widerrufen.
      </p>
      <p>
        <b>Cookies</b><br />
        Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“. Bei Cookies handelt es
        sich um kleine Textdateien, die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.)
        gespeichert werden, wenn Sie unsere Webseite besuchen.
        <br />Sie können Sie einzelne Cookies oder den gesamten Cookie-Bestand löschen. Darüber
        hinaus erhalten Sie Informationen und Anleitungen, wie diese Cookies gelöscht oder deren
        Speicherung vorab blockiert werden können. Je nach Anbieter Ihres Browsers finden Sie die
        notwendigen Informationen unter den nachfolgenden Links:
      </p>
      <ul>
        <li>
          Mozilla Firefox:
          https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen
        </li>
        <li>
          Internet Explorer:
          https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies
        </li>
        <li>Google Chrome: https://support.google.com/accounts/answer/61416?hl=de</li>
        <li>Opera: http://www.opera.com/de/help</li>
        <li>Safari: https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE</li>
      </ul>
      <br />

      <p>
        Speicherdauer und eingesetzte Cookies: <br />Soweit Sie uns durch Ihre Browsereinstellungen
        oder Zustimmung die Verwendung von Cookies erlauben, können folgende Cookies auf unseren
        Webseiten zum Einsatz kommen: <br />Technisch notwendige Cookies <br />Art und Zweck der
        Verarbeitung: <br />Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu
        gestalten. Einige Elemente unserer Internetseite erfordern es, dass der aufrufende Browser
        auch nach einem Seitenwechsel identifiziert werden kann. Der Zweck der Verwendung technisch
        notwendiger Cookies ist, die Nutzung von Websites für die Nutzer zu vereinfachen. Einige
        Funktionen unserer Internetseite können ohne den Einsatz von Cookies nicht angeboten werden.
        Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt
        wird.
      </p>
      <p>
        Für folgende Anwendungen benötigen wir Cookies:<br />
        Server-Logs - Beim Besuch dieser mit der Storytelling-Software "Pageflow" erstellten
        Webseite werden Informationen in so genannten "Server-Log-Dateien" übermittelt, verarbeitet
        und nach 30 Tagen automatisch gelöscht:
        <br />Browsertyp und Browserversion, verwendetes Betriebssystem, Referrer, URL, Hostname des
        zugreifenden Rechners, Uhrzeit der Serveranfrage, IP-Adresse
      </p>
      <p>
        Anhand dieser Informationen kann eine fehlerfreie Darstellung auf unterschiedlichen Geräten
        und Browsern gewährleistet und eine für die zur Verfügung stehende Netzwerksituation
        optimierte Version angezeigt werden. Eine Zusammenführung dieser Daten mit anderen
        Datenquellen wird nicht vorgenommen.
      </p>
      <p>
        Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
        Webseitenbetreiber hat ein berechtigtes Interesse an der technisch fehlerfreien Darstellung
        und der Optimierung seiner Webseite – hierzu müssen die Server-Log-Files erfasst werden.
      </p>
      <p>
        <b>Rechtsgrundlage und berechtigtes Interesse:</b><br />
        Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten
        Interesses an einer nutzerfreundlichen Gestaltung unserer Website.
      </p>
      <p>
        <i>Empfänger:</i><br />
        Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung
        unserer Website als Auftragsverarbeiter tätig werden.
        <br />Bereitstellung vorgeschrieben oder erforderlich: <br />Die Bereitstellung der
        vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben.
        Ohne diese Daten ist jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht
        gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder eingeschränkt
        sein.
      </p>
      <p>
        <b>Widerspruch</b><br />Lesen Sie dazu die Informationen über Ihr Widerspruchsrecht nach
        Art. 21 DSGVO weiter unten.
      </p>
      <p>
        <b>SSL-Verschlüsselung</b><br />Um die Sicherheit Ihrer Daten bei der Übertragung zu
        schützen, verwenden wir dem aktuellen Stand der Technik entsprechende
        Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
      </p>

      <p>
        <b>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</b><br />
        <i>Einzelfallbezogenes Widerspruchsrecht</i><br />
        Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit
        gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1
        lit. f DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt,
        Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung gestütztes Profiling im
        Sinne von Art. 4 Nr. 4 DSGVO.
        <br />Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr
        verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung
        nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung
        dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen. <br />Empfänger
        eines Widerspruchs: siehe Datenschutzbeauftragter der Universität
      </p>
      <p>
        <b>Änderung unserer Datenschutzbestimmungen</b><br />
        Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen
        rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der
        Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten
        Besuch gilt dann die neue Datenschutzerklärung.
      </p>
      <p>
        <b>Fragen an den Datenschutzbeauftragten</b><br />
        Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie
        sich direkt an die für den Datenschutz verantwortliche Person in unserer Organisation.
      </p>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";

export default {
  name: "DataPrivacy",
  components: { Footer },
};
</script>

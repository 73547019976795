import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import "../../client-shared/scss/style.scss";
import VueCroppie from "vue-croppie";
import "croppie/croppie.css"; // import the croppie css manually
import VueSocialSharing from "vue-social-sharing";
import VueHtml2pdf from "vue-html2pdf";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import VueConfirmDialog from "vue-confirm-dialog";
import VueMatomo from "vue-matomo";
import "@fontsource/roboto";
import { toBoolean } from "../../client-hubbel/src/services/utils";

Vue.config.productionTip = false;

Vue.use(VueCroppie);
Vue.use(VueSocialSharing);
Vue.use(VueHtml2pdf);
Vue.use(VueViewer);
Vue.use(VueConfirmDialog);

if (toBoolean(process.env.VUE_APP_USE_MATOMO)) {
  Vue.use(VueMatomo, {
    host: process.env.VUE_APP_MATOMO_HOST,
    siteId: process.env.VUE_APP_MATOMO_SITEID,
    trackerFileName: "matomo",
    router: router,
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: true,
    disableCookies: false,
    enableHeartBeatTimer: false,
    heartBeatTimerInterval: 15,
    debug: false,
    userId: undefined,
    cookieDomain: undefined,
    domains: undefined,
    preInitActions: [],
  });
}

Vue.config.productionTip = false;
new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <div class="topic-grid">
    <div class="topic-table">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        style="width: 30%; margin-left: 69%"
      />
      <v-data-table
        height="300px"
        :search="search"
        hide-default-footer
        :headers="table.headers"
        :items="topics"
      >
        <template v-slot:item="{ item }">
          <tr
            :class="selected_topic?.topic_id === item.topic_id ? 'active' : ''"
            @click="selected_topic = item"
          >
            <td>{{ item.topic_id }}</td>
            <td>{{ item.name }}</td>
            <td>
              <v-switch
                v-model="item.approved"
                :label="item.approved ? 'aktiviert' : 'deaktiviert'"
                style="width: 30%; margin: auto"
                @click.native.prevent="
                  updateTopicActive(item.topic_id, item.approved).then((res) => {
                    if (!res.data) {
                      item.approved = !item.approved;
                    }
                  })
                "
              />
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <div class="topic-tools">
      <div class="heading-three">Werkzeuge</div>
      <p />
      <v-divider style="margin-left: 5%; width: 90%; border-width: 2px !important" />
      <v-list v-if="selected_topic !== null">
        <v-list-item>
          <Button
            class="background-Information-Button"
            icon="$edit"
            label_text="Informationen Bearbeiten"
            :is-icon="true"
            @click.native="utilities.dialog = true"
          />
          <v-dialog v-model="utilities.dialog" width="70%">
            <v-card>
              <v-card-title>Ändern der Hintergrundinformationen</v-card-title>
              <div style="width: 90%; margin: auto">
                <v-textarea v-model="selected_topic.information" rows="10" auto-grow />
              </div>
              <!--              <div class="feed__info-btn">
                <Button
                  class="info-btn__button color-primary"
                  :icon="utilities.feed__info_isCollapsed ? 'chevronLeft' : 'chevronRight'"
                  label_text=""
                  is-cross
                  @click.native="
                    () => {
                      utilities.feed__info_isCollapsed = !utilities.feed__info_isCollapsed;
                      if(!utilities.feed__info_isCollapsed){this.$refs.Hintergrundinfos.innerHTML=selected_topic.information}
                    }
                  "
                />
                <p
                  class="info-btn__heading"
                  :class="{ 'd-none': !utilities.feed__info_isCollapsed }"
                >
                  Hintergrundinfos
                </p>
              </div>

              <div
                class="feed__info color-light-grey"
                :class="{
                  'feed__info&#45;&#45;collapsed': utilities.feed__info_isCollapsed,
                }"
              >
                <p class="heading-three">Hintergrundinfos:</p>
                <div ref="Hintergrundinfos" />
              </div>-->
            </v-card>
          </v-dialog>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import AdminApi from "@/services/admin-api";
import Button from "@/components/Button";

export default {
  name: "TopicOptions",
  components: { Button },
  data: () => ({
    table: {
      headers: [
        {
          text: "id",
          value: "topic_id",
          align: "center",
        },
        {
          text: "Name",
          value: "name",
          align: "center",
        },
        {
          text: "Aktiviert",
          value: "approved",
          align: "center",
        },
      ],
    },
    selected_topic: null,
    search: "",
    topics: [],
    utilities: {
      dialog: false,
      feed__info_isCollapsed: false,
    },
  }),
  mounted() {
    this.getAllTopics();
  },
  methods: {
    getAllTopics() {
      AdminApi.getAllTopics().then((res) => {
        res.data.forEach((topic) => this.topics.push(topic));
      });
    },
    updateTopicActive(topic_id, active) {
      return AdminApi.updateTopicActive(topic_id, active);
    },
  },
};
</script>

<style scoped></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-stepper',{staticClass:"stepper elevation-0 progress-bar-comp",attrs:{"alt-labels":""},model:{value:(_vm.e1),callback:function ($$v) {_vm.e1=$$v},expression:"e1"}},[_c('div',{staticClass:"progress-bar__header-wrapper"},[_c('v-stepper-header',{staticClass:"progress-bar__header"},[_c('v-stepper-step',{attrs:{"complete":_vm.e1 > 1,"step":"1","editable":_vm.e1 > 1}},[_vm._v(" Thema ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.e1 > 2,"step":"2","editable":_vm.e1 > 2}},[_vm._v(" Kategorie ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.e1 > 3,"step":"3","editable":_vm.e1 > 3}},[_vm._v(" Beitrag schreiben ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"4"}},[_vm._v(" Beitrag prüfen ")])],1)],1),_c('v-stepper-items',{staticClass:"progress-bar__content"},[_c('v-stepper-content',{staticClass:"content__step--topic",attrs:{"step":"1"}},[_c('h2',{staticClass:"content__heading"},[_vm._v("Zu welchem Thema passt dein Beitrag am besten?")]),_c('div',{staticClass:"content__topic-grid"},[(_vm.withoutTopicId >= 0)?_c('DashedCard',{attrs:{"title":"Ohne Thema","selected":_vm.selectedTopic.id === _vm.withoutTopicId},nativeOn:{"click":function($event){_vm.selectedTopic = { id: _vm.withoutTopicId, title: 'Ohne Thema', approved: false };
            _vm.toSecondStep();
            _vm.sendMatomoEvent(1);}}}):_vm._e(),(_vm.allowNewTopics)?_c('DashedCard',{attrs:{"title":"Neues Thema vorschlagen","icon":"$plus"},nativeOn:{"click":function($event){return _vm.openNewTopicDialog()}}}):_vm._e(),_vm._l((_vm.getApprovedTopics()),function(topic){return _c('Card',{key:topic.id,attrs:{"id":topic.id,"v-if":topic.approved === true,"title":topic.title,"no-pin":true,"no-last-post":true,"selected":_vm.selectedTopic?.id === topic.id || false},nativeOn:{"click":function($event){_vm.selectedTopic = topic;
            _vm.toSecondStep();
            _vm.sendMatomoEvent(1);}}})})],2),_c('div',{staticClass:"modal",attrs:{"id":"newTopicModal"}},[_c('div',{staticClass:"modal-content",attrs:{"id":"enterTopic"}},[_c('span',{staticClass:"close",on:{"click":function($event){return _vm.closeNewTopicDialog()}}},[_c('v-icon',[_vm._v("$x")])],1),_c('p',{staticClass:"heading-three font-dark-grey modal-heading"},[_vm._v("Neues Thema vorschlagen")]),_c('p',{staticClass:"font-dark-grey font-medium modal-text text-left"},[_vm._v(" Wie soll das neue Thema heißen? ")]),_c('v-text-field',{ref:"newTopic",attrs:{"placeholder":"Neues Thema","label":"Thema","input-id":"newTopic","rules":[
              (v) => {
                return !!v;
              },
            ]},model:{value:(_vm.newTopic),callback:function ($$v) {_vm.newTopic=$$v},expression:"newTopic"}}),_c('Button',{attrs:{"label_text":"Absenden","is-primary":true},nativeOn:{"click":function($event){return _vm.sendNewTopic()}}})],1),_c('div',{staticClass:"modal-content",staticStyle:{"display":"none"},attrs:{"id":"infoAndNext"}},[_c('span',{staticClass:"close",on:{"click":function($event){return _vm.closeNewTopicDialog()}}},[_c('v-icon',[_vm._v("$x")])],1),_c('p',{staticClass:"heading-three font-dark-grey modal-heading"},[_vm._v("Neues Thema vorschlagen")]),_c('p',{staticClass:"font-dark-grey font-medium modal-text"},[_vm._v(" Danke für deinen Themenvorschlag. Er wurde an die Redaktion gesendet und wird in Kürze bearbeitet. ")]),_c('Button',{attrs:{"label_text":"Weiter","is-primary":true},nativeOn:{"click":function($event){_vm.closeNewTopicDialogAndGoToTopic();
              _vm.sendMatomoEvent(0);
              _vm.sendMatomoEvent(1);}}})],1)]),_c('div',{staticClass:"content__button-row"},[_c('Button',{staticClass:"button-row__back",attrs:{"is-back":true},nativeOn:{"click":function($event){return _vm.$router.push('/')}}}),_c('Button',{staticClass:"button-row__next",attrs:{"is-primary":true,"label_text":"Weiter","disabled":_vm.selectedTopic.id !== null ? false : 'disabled'},nativeOn:{"click":function($event){_vm.toSecondStep();
            _vm.sendMatomoEvent(1);}}})],1)]),_c('v-stepper-content',{staticClass:"content__step--category",attrs:{"step":"2"}},[_c('h2',{staticClass:"content__heading"},[_vm._v("Zu welcher Kategorie gehört dein Beitrag?")]),_c('h2',{staticClass:"content__heading"},[_vm._v("Ich habe eine")]),_c('div',{staticClass:"content__category-grid"},[_c('Card',{staticClass:"category-grid__item",attrs:{"title":"Frage","category":"question","no-last-post":true,"no-pin":true,"selected":_vm.category === 'Question'},nativeOn:{"click":function($event){_vm.category = 'Question';
            _vm.toThirdStep();
            _vm.sendMatomoEvent(2);}}}),_c('h2',{staticClass:"content__heading category-grid__item category-grid__item--text"},[_vm._v("oder")]),_c('Card',{staticClass:"category-grid__item",attrs:{"title":"Information","category":"information","no-last-post":true,"no-pin":true,"selected":_vm.category === 'Information'},nativeOn:{"click":function($event){_vm.category = 'Information';
            _vm.toThirdStep();
            _vm.sendMatomoEvent(2);}}}),_c('h2',{staticClass:"content__heading category-grid__item category-grid__item--text"},[_vm._v("oder")]),_c('Card',{staticClass:"category-grid__item",attrs:{"title":"Veranstaltung","category":"event","no-last-post":true,"no-pin":true,"selected":_vm.category === 'Event'},nativeOn:{"click":function($event){_vm.category = 'Event';
            _vm.toThirdStep();
            _vm.sendMatomoEvent(2);}}})],1),_c('div',{staticClass:"content__button-row"},[_c('Button',{staticClass:"button-row__back",attrs:{"is-back":true},nativeOn:{"click":function($event){_vm.e1 = 1}}})],1)]),_c('v-stepper-content',{staticClass:"content__step--text",attrs:{"step":"3"}},[_c('PostCard',{ref:"editPostcard",staticClass:"content__postcard",attrs:{"postcard_info":Object.assign({ readOnly: false, topicFixed: true }, _vm.PostCard_Info)},on:{"writtenTextChanged":_vm.updateText}}),_c('div',{staticClass:"content__button-row"},[_c('Button',{staticClass:"button-row__back",attrs:{"is-back":true},nativeOn:{"click":function($event){_vm.e1 = 2}}}),_c('Button',{staticClass:"button-row__next",attrs:{"is-primary":true,"label_text":"Weiter","disabled":_vm.text.length > 0 ? false : 'disabled'},nativeOn:{"click":function($event){_vm.toFourthStep();
            _vm.sendMatomoEvent(3);}}})],1)],1),_c('v-stepper-content',{staticClass:"content__step--check",attrs:{"step":"4"}},[_c('PostCard',{ref:"verifyPostcard",staticClass:"content__postcard",attrs:{"postcard_info":Object.assign({ readOnly: true, approved: true }, _vm.PostCard_Info)}}),_c('div',{staticClass:"content__button-row"},[_c('Button',{staticClass:"button-row__back",attrs:{"is-back":true},nativeOn:{"click":function($event){_vm.e1 = 3}}}),_c('Button',{staticClass:"button-row__next",attrs:{"is-icon":true,"icon":this.isSubmitting ? 'mdi-email-fast-outline' : 'mdi-email-fast',"label_text":"Absenden","disabled":this.isSubmitting ? 'disabled' : false},nativeOn:{"click":function($event){_vm.sendCard();
            _vm.sendMatomoEvent(4);}}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="account-navigation-comp">
    <div v-if="!loggedIn()" class="account-nav--logged-out">
      <Button
        label_text="Registrieren"
        is-primary
        class="account-nav__button mb-1 mb-lg-0"
        @click.native="$router.push('/register')"
      />
      <Button
        label_text="Einloggen"
        isPrimary
        class="account-nav__button"
        @click.native="$router.push('/login')"
      />
    </div>

    <div
      v-if="loggedIn()"
      class="account-nav--logged-in"
      @mouseenter="open()"
      @mouseleave="close()"
    >
      <router-link to="/profile">
        <v-avatar v-if="!profilePicture" color="primary" class="shadow">
          <span class="account-nav__avatar--letter">{{ first_name.charAt(0) }}</span>
        </v-avatar>
        <v-avatar v-else size="50" class="shadow">
          <img :src="profilePicture" alt="Dein Profilbild" />
        </v-avatar>
      </router-link>

      <div v-if="isOpen" class="account-nav__sub-menu">
        <div class="color-white shadow">
          <router-link to="/profile"> Mein Bereich </router-link>
          <router-link to="/login" @click.native="logout"> Ausloggen </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from "@/components/Button";
import { getLoggedInUser } from "@/services/loggedIn";
import authApi from "@/services/auth-api";
import Api, { baseURL } from "@/services/api";

export default {
  name: "AccountNavigation",
  components: { Button },
  data: () => ({
    isOpen: false,
    profilePicture: null,
    first_name: "",
  }),
  mounted() {
    this.isOpen = window.innerWidth <= 960; // open on mobile
    let tempUser = getLoggedInUser();
    if (tempUser) {
      this.getProfilePicture();
      this.first_name = JSON.parse(tempUser).first_name;
    }
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = window.innerWidth <= 960; // always open on mobile, close on bigger screen
    },
    logout() {
      authApi.logout();
    },
    loggedIn() {
      return getLoggedInUser();
    },
    getProfilePicture() {
      Api.getProfilePicture().then((result) => {
        if (result.split(/[/\\]/gm)[0] === "profilePictures") {
          this.profilePicture = baseURL + "public/" + result;
        } else this.profilePicture = result;
      });
    },
  },
};
</script>

<template>
  <form class="shadow postcard-comp" action="">
    <div class="postcard__wrapper">
      <div v-if="!attachmentShown" class="postcard__front">
        <div class="postcard__right">
          <div class="postcard__date">
            <ul>
              <li>
                {{ getDateString(postcard_info.date) }}
              </li>
              <li>
                <v-img
                  v-if="
                    postcard_info.readOnly &&
                    ['PC', 'Phone', 'Mailbox'].includes(postcard_info.sentFrom)
                  "
                  :alt="'Created from ' + postcard_info.sentFrom"
                  contain
                  :src="
                    require(`../../../client-shared/img/stamp_${postcard_info.sentFrom.toLowerCase()}.svg`)
                  "
                  class="date__img date__img--stamp"
                />
                <v-img
                  alt="hubbel Logo"
                  contain
                  src="../../../client-shared/img/hubbel_logo.svg"
                  class="date__img date__img--logo"
                />
              </li>
            </ul>
          </div>
          <div
            v-if="
              loggedInUser != null &&
              loggedInUser.level_of_user !== 'normal' &&
              !postcard_info.readOnly
            "
            class="postcard__moderation"
          >
            <v-btn elevation="1" outlined class="approvePost" @click="approve(postcard_info)"
              >freischalten</v-btn
            >
            <v-btn elevation="1" outlined class="deletePost" @click="hide(postcard_info)"
              >löschen</v-btn
            >
          </div>
          <div
            v-if="
              loggedInUser != null &&
              loggedInUser.level_of_user !== 'normal' &&
              postcard_info.readOnly &&
              postcard_info.approved
            "
            class="postcard__moderation"
          >
            <v-btn elevation="1" outlined class="editPost" @click="makePostcardEditable()"
              >jetzt prüfen</v-btn
            >
          </div>
          <div class="postcard__sender">
            <ul>
              <li>Absender*in</li>
              <li>
                <div
                  v-if="
                    postcard_info.readOnly ||
                    postcard_info.topicFixed ||
                    postcard_info.sentFrom !== 'Mailbox'
                  "
                >
                  <!-- TODO when someone writes a postcard, the postcard already has to know if the user is verified to show this here -->
                  <b :class="'verified--' + postcard_info.sender_verified"
                    >{{ postcard_info.sender }}&nbsp;<v-img
                      v-if="postcard_info.sender_verified"
                      class="verified-icon"
                      src="../../../client-shared/img/verification_hubbel_v01.svg"
                      alt="(verified)"
                      width="18"
                      tabindex="1"
                    />
                  </b>
                </div>
                <div v-else class="selectQuestion">
                  <v-text-field v-model="senderName" dense clearable />
                </div>
              </li>
            </ul>
          </div>
          <div class="postcard__subject">
            <ul>
              <br />
              <li>Thema:</li>
              <li>
                <div v-if="postcard_info.readOnly || postcard_info.topicFixed">
                  <router-link :to="`/Forum/${postcard_info.subject}`" class="subject__link">
                    <b>{{ postcard_info.subject }}</b>
                  </router-link>
                </div>
                <div v-else class="selectQuestion">
                  <select v-model="selectedTopic">
                    <option
                      v-for="topic in possibleTopics"
                      :key="topic.topic_id"
                      :value="topic.topic_id"
                      :title="topic.name"
                    >
                      {{
                        topic.name.substr(0, 15) + (topic.name.length > 15 ? "..." : "")
                        /*TODO: A line-break would be preferred here*/
                      }}
                    </option>
                  </select>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="postcard__vertical-line" />
        <div class="postcard__left">
          <div v-if="postcard_info.readOnly || postcard_info.topicFixed">
            <label
              v-if="postcard_info.category === 'Information'"
              class="postcard__category postcard__category--information"
              >Information
            </label>
            <label
              v-if="postcard_info.category === 'Question'"
              class="postcard__category postcard__category--question"
              >Frage
            </label>
            <label
              v-if="postcard_info.category === 'Event'"
              class="postcard__category postcard__category--event"
              >Veranstaltung
            </label>
          </div>
          <div v-else class="selectInformation" :class="'selectInformation--' + category">
            <select v-model="category">
              <option value="Information">Information</option>
              <option value="Question">Frage</option>
              <option value="Event">Event</option>
            </select>
          </div>

          <br />
          <div>
            <p
              v-if="postcard_info.readOnly"
              class="postcard__message font-regular"
              :class="{
                'postcard__message--moderationAdjustment':
                  loggedInUser != null && loggedInUser.level_of_user !== 'normal',
              }"
            >
              <span
                v-if="
                  postcard_info.approved ||
                  (loggedInUser != null && postcard_info.sender === fullName(loggedInUser))
                "
                >{{ postcard_info.text }}
              </span>
              <span v-else class="dummyText">
                {{ dummyText }}
              </span>
            </p>
            <div v-else>
              <textarea
                v-model="writtenText"
                class="postcard__message postcard__message--editable font-regular"
                name="message"
                maxlength="500"
                rows="12"
                cols="40"
                :placeholder="
                  postcard_info.text.length > 0
                    ? postcard_info.text
                    : 'Inhalt (maximal 500 Zeichen)'
                "
              />
              <v-icon class="message__paper-clip" @click="openInput()"> $paperclip </v-icon>
              <span class="message__text-length">{{ writtenText.length }}/500</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="attachmentShown" class="postcard__back">
        <Attachment
          :attachments="attachments"
          :postcard_id="postcard_info.id"
          :read-only="postcard_info.readOnly"
          @deleteAttachment="deleteAttachment"
          @openInput="openInput()"
        />
      </div>
    </div>
    <Button
      v-if="!postcard_info.readOnly || (attachments.length > 0 && postcard_info.approved)"
      id="attachmentSwitcher"
      :is-anhang="true"
      icon="paperclip"
      :label_text="
        attachmentShown
          ? 'Postkarte anzeigen'
          : attachments.length === 0
          ? 'Anhang hinzufügen'
          : attachments.length + (attachments.length === 1 ? ' Anhang' : ' Anhänge')
      "
      class="postcard__attachment-switcher"
      @click.native="toggleAttachment($event)"
    />
    <input
      v-if="!postcard_info.readOnly"
      id="fileChooser"
      type="file"
      class="fileChooser"
      accept="image/*,.pdf"
      multiple
      @change="uploadAttachments()"
    />
  </form>
</template>

<script>
import Button from "@/components/Button";
import Attachment from "@/components/Attachment";
import { baseURL } from "@/services/api";
import Api from "../services/api";

export default {
  name: "PostCard",
  components: {
    Attachment,
    Button,
  },
  props: {
    postcard_info: { type: Object },
    loggedInUser: { type: Object },
  },
  data: () => ({
    writtenText: null,
    category: null,
    selectedTopic: null,
    senderName: null,
    possibleTopics: [],
    attachmentShown: false,
    attachments: [],
  }),
  computed: {
    dummyText() {
      return "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.";
    },
  },
  watch: {
    writtenText: function (val, oldVal) {
      if (val !== oldVal) this.$emit("writtenTextChanged", val);
    },
  },
  created() {
    this.attachments = this.postcard_info.attachments
      ? this.renderAttachments(this.postcard_info.attachments)
      : [];
    this.writtenText = this.postcard_info.text;
    this.category = this.postcard_info.category;
    this.senderName = this.postcard_info.sender_name_mailbox;

    this.possibleTopics = this.postcard_info.allTopicNames || [];
    this.selectedTopic =
      this.possibleTopics.find((elem) => elem.name === this.postcard_info.subject)?.topic_id || 0;
  },
  methods: {
    makePostcardEditable() {
      this.postcard_info.readOnly = false;
    },
    approve(postcard) {
      this.$confirm({
        title: "Freischalten?",
        message: "Soll die Postkarte endgültig freigeschaltet werden?",
        button: {
          no: "Nein, abbrechen!",
          yes: "Ja, freischalten!",
        },
        callback: (confirm) => {
          if (confirm) {
            postcard.text = this.getText();
            postcard.category = this.getCategory();
            postcard.topic_id = this.getSelectedTopic();
            postcard.sender_name = this.getSenderName();
            Api.updateAndApprovePostcard(postcard).then((res) => {
              if (res.status === 200) this.$router.go(0); // Refreshes site if approval was successful
            });
          }
        },
      });
    },
    hide(postcard) {
      this.$confirm({
        title: "Löschen?",
        message: "Soll die Postkarte endgültig gelöscht werden?",
        button: {
          no: "Nein, abbrechen!",
          yes: "Ja, löschen!",
        },
        callback: (confirm) => {
          if (confirm) {
            Api.hidePostcard(postcard).then((res) => {
              if (res.status === 200) this.$router.go(0); // Refreshes site if approval was successful
            });
          }
        },
      });
    },
    fullName(loggedInUser) {
      return loggedInUser.first_name + " " + loggedInUser.last_name;
    },
    getText() {
      return this.writtenText || "empty";
    },
    getCategory() {
      return this.category || false;
    },
    getSelectedTopic() {
      return this.selectedTopic || "";
    },
    getSenderName() {
      return this.senderName || "";
    },
    getDateString(cardDate) {
      const date = new Date(cardDate.replace(/\s/, "T") + "Z"); // https://stackoverflow.com/questions/21883699/safari-javascript-date-nan-issue-yyyy-mm-dd-hhmmss
      let dateString;

      dateString =
        ("0" + date.getDate()).slice(-2) +
        "." +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "." +
        date.getFullYear();

      return dateString;
    },
    getAttachments() {
      return this.attachments;
    },
    renderAttachments(attachments) {
      const att = [];
      attachments.forEach((attach) => {
        att.push({
          name: attach.name,
          type: attach.static_file_path.split(".").pop(),
          src: baseURL + "public/" + attach.static_file_path,
        });
      });
      return att;
    },
    pushRenderedAttachments(attachments) {
      this.attachments = attachments;
    },
    openInput() {
      document.getElementById("fileChooser").click();
    },
    uploadAttachments() {
      let fileList = document.getElementById("fileChooser").files;
      const attachments = this.attachments;

      if (!fileList) return;

      for (let i = 0; i < fileList.length; i++) {
        //const serverUrl = uploadToServer(fileList[i]);

        const fileReader = new FileReader();
        fileReader.readAsDataURL(fileList[i]);
        fileReader.addEventListener("load", function () {
          attachments.push({
            name: fileList[i].name,
            type: fileList[i].type.split("/")[1],
            //src: serverUrl
            src: this.result,
          });
        });
      }
      this.attachmentsVisualFeedback();
    },

    deleteAttachment(index) {
      this.attachments.splice(index, 1);
    },

    toggleAttachment(event) {
      event.stopPropagation();
      this.attachmentShown = !this.attachmentShown;
      if (
        this.attachmentShown &&
        this.attachments.length > 0 &&
        this.postcard_info.id !== undefined &&
        this.postcard_info.id !== null &&
        typeof this.postcard_info.id !== "undefined"
      ) {
        window._paq.push([
          "trackEvent",
          "Attachments",
          "Attachments Click",
          "Attachment Section of Postcard " + this.postcard_info.id + " selected",
        ]);
      }
    },

    attachmentsVisualFeedback() {
      const switcher = document.getElementById("attachmentSwitcher");
      const initialColor = switcher.style.backgroundColor;
      const hoverColor = "#c5e388"; //TODO make class

      switcher.style.backgroundColor = hoverColor;

      setTimeout(() => {
        switcher.style.backgroundColor = initialColor;
      }, 200);
      setTimeout(() => {
        switcher.style.backgroundColor = hoverColor;
      }, 400);
      setTimeout(() => {
        switcher.style.backgroundColor = initialColor;
      }, 600);
    },
  },
};
</script>

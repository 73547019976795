<template>
  <v-app-bar app class="navbar-comp">
    <router-link to="/">
      <v-img
        alt="hubbel Logo"
        class="shrink mr-md-2"
        contain
        src="../../../client-shared/img/hubbel_logo.svg"
        transition="scale-transition"
        width="60"
        @click="$router.push('/')"
      />
    </router-link>

    <v-spacer />
    <v-toolbar-items class="d-none d-md-flex navbar__items">
      <MenuItem
        v-for="item in menu"
        :key="item.to"
        :to="item.to"
        :title="item.title"
        :class="{ active: name === item.active }"
      />
    </v-toolbar-items>

    <AccountNavigation ref="Account" class="d-none d-md-block" />

    <MobileNavigation :menu="menu" :name="name" />
  </v-app-bar>
</template>

<script>
import MenuItem from "./MenuItem";
import AccountNavigation from "./override/AccountNavigation";
import MobileNavigation from "@/components/MobileNavigation";
import config from "@/config.json";

export default {
  name: "Navbar",
  components: {
    MobileNavigation,
    AccountNavigation,
    MenuItem,
  },
  props: {
    name: {
      type: String,
    },
  },
  data: () => ({
    menu: config.menu,
  }),
  methods: {
    changeProfilePicture() {
      this.$refs.Account.login();
    },
  },
};
</script>

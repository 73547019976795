<template>
  <div class="card-comp">
    <v-card
      class="mx-auto ma-3 card ribbon-wrapper"
      :class="['card--' + category, selected ? 'card--selected' : '']"
    >
      <v-card-title class="card__title">
        <div
          class="title__icon title__icon--clickable"
          :class="{ hide: noPin }"
          @click="pinElement($event)"
        >
          <v-icon v-if="!localPinned"> $pin </v-icon>
          <v-icon v-if="localPinned"> $pinFilled </v-icon>
        </div>
        <div class="ribbon ribbon-top-right" :class="{ 'd-none': topic_age < 0 || topic_age > 7 }">
          <span class="ribbon__content">Neues Thema</span>
        </div>
      </v-card-title>
      <v-card-text class="card__text" :class="category ? 'card__text--colored' : ''">
        {{ title }}
      </v-card-text>
      <v-card-actions class="card__date" :class="{ hide: noLastPost }">
        <p>{{ last_post }}</p>
      </v-card-actions>
    </v-card>
    <div id="pinModal" class="modal" @click="closeDialog($event)">
      <div class="modal-content">
        <span class="close" @click="closeDialog($event)"><v-icon>$x</v-icon></span>
        <p class="heading-three font-dark-grey modal-heading">Thema anpinnen</p>
        <p class="font-dark-grey font-medium modal-text">
          Du kannst Themen anpinnen, wenn du ein Konto angelegt hast. Möchtest du jetzt ein eigenes
          Profil erstellen?
        </p>
        <Button
          label_text="Profil erstellen"
          :is-primary="true"
          @click.native="
            $event.stopPropagation();
            $router.push('/register');
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import Api from "@/services/api";
import { getLoggedInUser } from "@/services/loggedIn";

export default {
  name: "Card",
  components: {
    Button,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    last_post: {
      type: String,
    },
    topic_age: {
      type: Number,
      default: -1,
    },
    noPin: {
      type: Boolean,
      required: false,
    },
    pinned: {
      type: Boolean,
      required: false,
      default: false,
    },
    noLastPost: {
      type: Boolean,
      required: false,
    },
    category: {
      type: String,
      required: false,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    localPinned: false,
  }),
  created() {
    this.localPinned = this.pinned;
  },
  methods: {
    pinElement(event) {
      event.stopPropagation();
      if (getLoggedInUser()) {
        if (this.id) {
          Api.setTopicPinned(this.id).then((res) => (this.localPinned = res.data));
        }
      } else {
        this.openDialog();
      }
    },
    openDialog() {
      var modal = document.getElementById("pinModal");
      modal.style.display = "block";
    },
    closeDialog(event) {
      event.stopPropagation();
      var modal = document.getElementById("pinModal");
      modal.style.display = "none";
    },
  },
};
</script>

<template>
  <div class="custom-page">
    <h1>Hilfe und FAQ</h1>
    <br />

    <div class="custom-page__content">
      <h2>Hilfe</h2>
      <p>
        Hier sind Hilfestellungen aufgelistet, die dir helfen, den hubbel optimal zu bedienen und zu
        nutzen.
      </p>
      <h3>Wo kann ich eine Postkarte verfassen?</h3>
      <p>
        Postkarten kannst du handschriftlich direkt am hubbel verfassen und, wie in einen
        Briefkasten, einwerfen oder digital direkt über unsere hubbel-Webseite verfassen und
        absenden.
      </p>
      <h3>Wie kann ich eine analoge Postkarte verfassen?</h3>
      <p>
        Bist du am hubbel vor Ort, kannst du aus dem Postkartenspender oben links eine Postkarte
        entnehmen. <br />
        Verfasse auf ihr deine mitgebrachte Frage oder Information. Wenn du eine Quelle für deine
        Information hast, wäre es schön, wenn du sie ebenfalls angibst. Anschließend kannst du deine
        beschriebene Postkarte, ohne dich vorher anmelden zu müssen, in den Briefkastenschlitz des
        hubbels werfen. Ist sie fertig verarbeitet, bittet der hubbel dich anzugeben, zu welchem
        Thema die Postkarte gehört und ob es sich um eine Frage oder Information handelt.
        Anschließend einmal bestätigen und dann liegt deine Postkarte der Redaktion zur Überprüfung
        vor, die sie dann schnellstmöglich freischaltet!
      </p>
      <h3>Wie kann ich eine digitale Postkarte verfassen?</h3>
      <p>
        Du kannst auf unserer Webseite auch digitale Postkarten verfassen, nachdem du dich vorab
        registriert hast. Hast du dich registriert findest du unten rechts auf der Startseite und
        auf der Seite jedes Themas ein Feld "Postkarte schreiben". Einmal draufgeklickt, wirst du
        weitergeleitet und wählst zuerst das Thema deiner mitgebrachten Frage oder Information aus
        (Achtung: Schreibst du eine Postkarte direkt aus einer Themenseite, wird dieses Thema
        automatisch ausgewählt). Nach einem weiteren Klick wählst du aus, ob es sich bei deiner
        Postkarte um eine Frage oder Information handelt. Im Anschluss, kannst auf der nächsten
        Seite deine Postkarte verfassen. Wenn du eine Quelle für deine Information hast, wäre es
        schön, wenn du sie ebenfalls angibst. Du kannst deine Postkarte auch um Foto- oder
        pdf-Anhänge ergänzen. Anschließend einmal bestätigen und dann liegt deine Postkarte der
        Redaktion zur Überprüfung vor, die sie dann schnellstmöglich freischaltet!
      </p>
      <h3>Wie kann ich ein neues Thema vorschlagen?</h3>
      <p>
        Du willst eine Postkarte zu einem Thema schreiben, das noch nicht existiert? Kein Problem!
        Bei der Themenauswahl für deine geschriebene Postkarte kannst du die Option "Neues Thema
        vorschlagen" wählen. Dein Themenvorschlag wird anschließend von der Redaktion überprüft und
        deine Postkarte wird unter diesem Thema freigeschaltet.
      </p>
      <h3>Meine Postkarte passt in kein bisheriges Thema!</h3>
      <div>
        Du willst eine Postkarte zu einem Thema schreiben, das noch nicht existiert oder du weißt
        nicht, zu welchem Thema deine Postkarte gehört? Kein Problem! Bei der Themenauswahl für
        deine geschriebene Postkarte kannst du die Option "Neues Thema vorschlagen" oder "Ohne
        Thema" wählen.
        <ul>
          <li>
            Wählst du die Option "Neues Thema vorschlagen", kannst du ein neues Thema vorschlagen,
            dein Themenvorschlag wird anschließend von der Redaktion überprüft und deine Postkarte
            wird unter diesem Thema freigeschaltet.
          </li>
          <li>
            Wählst du die Option "Ohne Thema", kannst du deine Postkarte erstmal ohne Thema
            verfassen, die Redaktion ordnet bei der Überprüfung im Anschluss deine Postkarte in ein
            passendes Thema ein oder erstellt, bei Bedarf, ein neues.
          </li>
        </ul>
      </div>
      <br />
      <br />
      <br />
      <h2>FAQ</h2>
      <h3>Wer steht hinter dem hubbel?</h3>
      <p>
        Der hubbel ist das Ergebnis eines Projekts im Forschungsverbund ForDemocracy. In
        Zusammenarbeit mit den Bürger*innen des Hublands wurde der hubbel im Projekt von Franzisca
        Maas (<a href="mailto:franzisca.maas@uni-wuerzburg.de">franzisca.maas@uni-wuerzburg.de</a>)
        an der Universität Würzburg, Lehrstuhl für Psychologische Ergonomie entwickelt. Das Projekt
        sich zu Aufgabe gemacht, technologiebasierte Partizipationstools für die Kommunalpolitik zu
        entwickeln und evaluieren.
      </p>
      <h3>Wie wird der hubbel finanziert?</h3>
      <p>
        Der hubbel ist das Ergebnis eines Teilprojekts des bayerischen Forschungsverbunds Zukunft
        der Demokratie (ForDemocracy). Finanziert wird das auf vier Jahre (2018-2022) ausgelegte
        Forschungsvorhaben vom Bayerischen Staatsministerium für Wissenschaft und Kunst. Aktuell
        läuft die Finanzierung bis zum Ende des Jahres 2022.
      </p>
      <h3>Muss ich Hubländer*in sein, um am hubbel mitzumachen?</h3>
      <p>
        Nein, alle Menschen, die wichtige und interessante Informationen oder Fragen zu
        Geschehnissen am Hubland haben, sind herzlich dazu eingeladen mitzumachen! Je mehr Leute
        mitmachen, desto mehr Informationen können wir für das Hubland sammeln und verbreiten.
      </p>
      <h3>
        Was bedeuten die blauen Haken
        <v-img
          class="verified-icon"
          src="../../../client-shared/img/verification_hubbel_v01.svg"
          alt="(verified)"
          width="18"
          tabindex="1"
        />
        hinter einigen Absender*innen?
      </h3>
      <p>
        Der blaue Haken zeigt an, dass hinter der Absender*in eine offizielle Firma, Organisation
        oder ein Mitglied der hubbel-Redaktion steckt. Er zeigt an, dass die Absender*in überprüft
        wurde und es sich um das echte Konto dieser Firma, Organisation oder des Redaktions-Mitglied
        handelt.
      </p>
      <h3>Wie geht es nach diesem Test weiter?</h3>
      <p>
        In diesem zweiten Test des hubbels wird das zugrundeliegende Konzept zum ersten Mal über
        einen längeren Zeitraum getestet. Ihr, die späteren Nutzer*innen, könnt den hubbel auf Herz
        und Nieren prüfen, damit in der darauffolgenden Überarbeitung des hubbels eine verbesserte
        Version entsteht, die dann in der Zukunft ein fester Bestandteil des Hublands werden soll.
        Dazu wird das Projekt, nach Ende der Förderung des bayerischen Forschungsverbunds Zukunft
        der Demokratie (ForDemocracy), an die Stadt übergeben.
      </p>
      <h3>Für die Zukunft geplante Funktionen des hubbel</h3>
      <div>
        Folgende Funktionen sind für die Zukunft des hubbels vorgesehen, haben es aber noch nicht in
        den aktuellen Test geschafft:
        <ul>
          <li>
            Im hubbel integrierter Drucker, damit Informationen vom hubbel, auf Postkarten gedruckt,
            ganz leicht mitgenommen werden können
          </li>
          <li>Ein integrierter Kalender für Veranstaltungen am Hubland</li>
          <li>Die Möglichkeit direkt auf ausgewählte Postkarten antworten zu können</li>
          <li>Eine mobile Version der Webseite oder sogar App</li>
          <li>Sprachbedienung des hubbels</li>
        </ul>
        Fallen euch noch weitere Funktionen ein, die den hubbel erweitern können und euch aktuell
        fehlen? Dann teilt sie uns gerne via
        <a href="mailto:hubbelredaktion@protonmail.com">E-Mail</a> mit!
      </div>
      <br />
      <h3>Was sind das eigentlich für Symbole auf den Rändern der Postkarten?</h3>
      <p>¯\_(ツ)_/¯ .....was erkennst du denn in den Symbolen?</p>
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";

export default {
  name: "Help",
  components: { Footer },
};
</script>

<template>
  <div class="custom-page">
    <h1>Nutzungsbedingungen</h1>
    <div class="custom-page__subtitle-note">Version 1.0, Stand: 02.08.2022</div>

    <div class="custom-page__content">
      <p>
        Willkommen beim hubbel! Die nachfolgenden Nutzungsbedingungen regeln die kostenlose Nutzung
        des hubbel durch registrierte Nutzende (nachfolgend: „Mitgliedschaft“) über das Internet,
        mobile Dienste, Apps, oder den Briefkasten-hubbel.
      </p>

      <h2>1. Konto beim hubbel</h2>
      <p>
        <b>Anmeldung und Registrierung</b><br />
        Die Mitgliedschaft kommt nach dem Ausfüllen deiner Mitgliedschaftsdaten durch deine
        Bestätigung durch Klicken auf den „registrieren“ Button zustande.
      </p>
      <p>
        Du musst mindestens 18 Jahre und voll geschäftsfähig sein oder mindestens 12 Jahre alt sein
        und dich mit Zustimmung deiner Erziehungsberechtigten und gesetzlichen Vertreter anmelden.
      </p>
      <p>
        Bei der Anmeldung musst du wahrheitsgemäße, aktuelle und vollständige Angaben machen.
        Mehrfache Anmeldungen unter verschiedenen Mitgliedsnamen sind untersagt; eine erneute
        Anmeldung, wenn wir eine Mitgliedschaft gekündigt und die Neuanmeldung verboten hatten, ist
        ebenfalls untersagt.
      </p>
      <p>
        Für kommunale und gemeinnützige Organisationen und vergleichbare Einrichtungen (z. B.
        Stadtverwaltungen) können wir einen „verifizierten Account" anlegen. Dieser ermöglicht es
        Organisationen beispielsweise in den Themen des hubbel mit Bürger*innen zu kommunizieren und
        einen Überblick über aktuelle Entwicklungen in der Nachbarschaft zu bekommen. Postkarten von
        verifizierten Accounts sind für alle Nutzenden des hubbel optisch als „verifiziert“
        gekennzeichnet.
      </p>
      <p>
        <b>Wer kann meine Postkarten und Daten sehen?</b><br />
        Von dir verfasste Postkarten im hubbel zeigen deinen vollständigen Vornamen und auf den
        ersten Buchstaben verkürzten Nachnamen (z.B. „Franzisca M.“). Dadurch möchten wir das
        Vertrauen in die Inhalte der Postkarten und die Verbundenheit im Stadtteil stärken. Dein
        Profil und damit dein Profilbild, die Information über deinem Hubland-Status (z.B.
        Anwohner*in) und deine Rolle (z.B. Moderator*in) ist nicht für andere Nutzende einsehbar.
      </p>
      <p>
        <b>Laufzeit und Kündigung</b><br />
        Die Mitgliedschaft läuft auf unbestimmte Zeit. Sie kann von dir und /oder uns jederzeit mit
        sofortiger Wirkung ordentlich gekündigt werden.
      </p>

      <h2>2. Nutzung des hubbel</h2>
      <p>
        Bei der Nutzung des hubbel verpflichtest du dich, die geltenden gesetzlichen Vorschriften zu
        beachten. Das betrifft z.B. den Schutz der Persönlichkeitsrechte anderer Nutzer oder das
        Urheberrecht an Fotos.
      </p>
      <p>
        Die Nutzung des hubbel ist kostenlos. Wir übernehmen keine Garantie oder Gewähr für
        Informationen im hubbel, für Inhalte anderer Nutzer oder für eine bestimmte Verfügbarkeit
        oder Funktionalität des hubbel.
      </p>
      <p>
        Eine Redaktion aus Mitarbeitenden des Lehrstuhls für Psychologische Ergonomie sowie
        freiwilligen Anwohner*innen des Hublands überprüfen Postkarten für den hubbel vorab nach
        bestem Wissen und Gewissen auf Hassrede, inhaltliche Korrektheit und gegebenenfalls
        Rechtschreibung. Wir behalten uns aber vor, sowohl Nutzer*innen als auch ihre Inhalte
        jederzeit zu sperren oder zu löschen. Dies würden wir vor allem dann tun, wenn wir Kenntnis
        von einem Verstoß gegen geltendes Recht in einem Inhalt erhalten. Eine Löschung und / oder
        Sperrung aus anderen Gründen behalten wir uns ausdrücklich vor. Diese Gründe liegen in
        unserem Ermessen. Wir sind nicht dazu verpflichtet, die Nutzer*innen über diese Gründe zu
        informieren.
      </p>
      <p>
        Du bleibst Inhaber*in deiner Inhalte (z.B. von dir im hubbel veröffentlichte Postkarten und
        Anhänge wie Fotos). Wenn an deinem Inhalt ein Schutzrecht besteht (z.B. ein Urheberrecht an
        einem Foto oder Text), gewährst du uns das Recht, den Inhalt für den Betrieb von hubbel zu
        nutzen. Dieses Nutzungsrecht ist nicht-exklusiv und zeitlich und räumlich unbeschränkt.
        Soweit hierfür erforderlich, dürfen wir das Nutzungsrecht auch an Dritte übertragen oder
        unterlizenzieren.
      </p>
      <h2>3. Änderung der Nutzungsbedingungen</h2>
      <p>
        Mit deiner Zustimmung oder wenn deine Zustimmung nach den folgenden Bestimmungen als erteilt
        gilt, können wir die mit dir vereinbarten Nutzungsbedingungen anpassen (z.B. bei Einführung
        neuer Funktionen).
      </p>
      <p>
        Wenn wir die Nutzungsbedingungen ändern möchten, teilen wir dir dies mindestens sechs Wochen
        vor dem vorgeschlagenen Wirksamwerden in Textform (also z.B. per E-Mail) mit. Widersprichst
        du nicht innerhalb von sechs Wochen nach Zugang dieser Mitteilung in Textform, gilt deine
        Zustimmung zur Änderung als erteilt und die Änderungen werden zum angekündigten Zeitpunkt
        wirksam. Auf diese Folge weisen wir dich in der Änderungsmitteilung besonders hin.
      </p>
      <h2>4. Datenschutz</h2>
      <p>
        Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der
        EU-Datenschutzgrundverordnung (DSGVO), ist:<br />
        Der behördlich bestellte Datenschutzbeauftrage der Julius-Maximilians-Universität
        Würzburg<br />
        Sanderring 2<br />
        97070 Würzburg<br />
        Telefon: +49 931 31-0<br />
        Email: datenschutz@uni-wuerzburg.de<br />
      </p>
      <b>Ihre Betroffenenrechte</b><br />
      Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit
      folgende Rechte ausüben:
      <ul>
        <li>
          Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),
        </li>
        <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
        <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
        <li>
          Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten
          noch nicht löschen dürfen (Art. 18 DSGVO),
        </li>
        <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
        <li>
          Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen
          Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).
        </li>
      </ul>
      <br />
      <p>
        Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für
        die Zukunft widerrufen.
      </p>
      <p>
        <b>Rechtsgrundlage und berechtigtes Interesse:</b><br />Die Verarbeitung erfolgt gemäß Art.
        6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an einer nutzerfreundlichen
        Gestaltung unserer Website.
      </p>

      <p>
        <i>Empfänger:</i><br />Empfänger der Daten sind ggf. technische Dienstleister, die für den
        Betrieb und die Wartung unserer Website als Auftragsverarbeiter tätig werden.<br />
        Bereitstellung vorgeschrieben oder erforderlich:<br />
        Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch
        vertraglich vorgeschrieben. Ohne diese Daten ist jedoch der Dienst und die
        Funktionsfähigkeit unserer Website nicht gewährleistet. Zudem können einzelne Dienste und
        Services nicht verfügbar oder eingeschränkt sein.
      </p>

      <p>
        <b>Widerspruch</b><br />Lesen Sie dazu die Informationen über Ihr Widerspruchsrecht nach
        Art. 21 DSGVO weiter unten.
      </p>

      <p>
        <b>SSL-Verschlüsselung</b><br />Um die Sicherheit Ihrer Daten bei der Übertragung zu
        schützen, verwenden wir dem aktuellen Stand der Technik entsprechende
        Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
      </p>

      <p>
        <b>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</b><br />
        <i>Einzelfallbezogenes Widerspruchsrecht</i><br />Sie haben das Recht, aus Gründen, die sich
        aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender
        personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO (Datenverarbeitung auf der
        Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch für ein
        auf diese Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO. <br />Legen Sie
        Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei
        denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
        Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der
        Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen. <br />Empfänger eines
        Widerspruchs: siehe Datenschutzbeauftragter der Universität
      </p>

      <p>
        <b>Änderung unserer Datenschutzbestimmungen</b><br />
        Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen
        rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der
        Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten
        Besuch gilt dann die neue Datenschutzerklärung.
      </p>
      <p>
        <b>Fragen an den Datenschutzbeauftragten</b><br />
        Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie
        sich direkt an die für den Datenschutz verantwortliche Person in unserer Organisation.
      </p>

      <p>
        <b>Aufruf unserer Webseite / Zugriffsdaten</b><br />
        Wir erheben keine Nutzungsdaten.
      </p>
      <p>
        <b>So kannst du uns kontaktieren</b><br />Du kannst uns eine E-Mail an
        hubbelredaktion@protonmail.com schicken. Die Rechtsgrundlage ist Artikel 6, Absatz 1(b) der
        DSGVO, soweit deine Angaben zur Beantwortung deiner Anfrage benötigt werden, und im Übrigen
        Artikel 6, Absatz 1(f) der DSGVO aufgrund unseres berechtigten Interesses, dass du mit uns
        Kontakt aufnimmst und wir deine Anfrage beantworten können.
      </p>
      <p>
        <b>Registrierung</b><br />Wenn du dich entschließt, dich für unseren Login-Bereich zu
        registrieren, wirst du dazu aufgefordert, uns personenbezogene Daten mitzuteilen. Ohne diese
        Daten ist eine Registrierung nicht möglich. Du kannst die Inhalte im hubbel auch ohne
        Registrierung lesen, aber über die Webseite keine Postkarten einspeisen. <br />Bei einer
        Registrierung wird zum Schutz gegen Mehrfachanmeldungen und zur eindeutigen Zuordnung einer
        Person zu einem Account deine Emailadresse erfragt. Wir verwenden und speichern dafür die
        folgenden personenbezogenen Daten: Emailadresse, vollständiger Name, Hubland-Status und
        gegebenenfalls deine Rolle (z.B. Moderator*in).
      </p>
      <h2>5. Alternative Streitbeilegung gemäß Verbraucherstreitbeilegungsgesetz</h2>
      <p>
        Wir weisen dich darauf hin, dass wir nicht bereit und nicht verpflichtet sind, an
        Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>
      <h2>6. Anwendbares Recht</h2>
      <p>Es gilt deutsches Recht.</p>
      <h2 class="bigger">Postkartenrichtlinien</h2>
      <p>
        <b>Beim hubbel gelten folgende Regeln</b><br />
        Der hubbel soll es Menschen am Hubland erleichtern sich gegenseitig über neue Entwicklungen
        zu informieren, Fragen stellen zu können und sich im Sinne einer guten Gemeinschaft einander
        auszuhelfen.
      </p>
      <p>
        Im hubbel sind alle Themen rund um das Hubland willkommen. Wenn du hier wohnst, arbeitest,
        studierst, dich aufhältst oder dich einfach nur für das Hubland interessierst, bist du beim
        hubbel richtig!
      </p>
      <p>
        Der hubbel soll euch allen als Austausch- und Unterstützungsplattform dienen, deswegen gibt
        es ein paar Regeln für hubbel-Postkarten.
        <br />Wir behalten uns vor Inhalte zu löschen, die gegen diese Regeln verstoßen. Dazu
        gehören:
      </p>
      <ul>
        <li>Hassrede</li>
        <li>Falschmeldungen und Verschwörungserzählungen</li>
        <li>
          Beleidigungen, Entwürdigungen und Diskriminierungen von Personen, insbesondere aufgrund
          ihrer Religion, Herkunft, Nationalität, körperlichen Verfassung, Einkommensverhältnisse,
          sexuellen Identität, Alters, ihrer Hautfarbe oder Geschlechts
        </li>
        <li>
          Verletzungen von Rechten Dritter (Personen, Institutionen, Organisationen, Unternehmen)
        </li>
        <li>Aufforderung zu Gewalt</li>
        <li>
          Die Veröffentlichung privater Korrespondenz (Briefe, E-Mails und dergleichen) mit Dritten
          ohne deren Zustimmung
        </li>
        <li>
          Die Veröffentlichung von privaten Anschriften, E-Mail-Adressen und Telefonnummern Dritter
          ohne deren Zustimmung
        </li>
        <li>Politischer und religiöser Extremismus und Rassismus</li>
      </ul>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";

export default {
  name: "TermsOfUse",
  components: { Footer },
};
</script>
